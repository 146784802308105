<template>

    <div class="dewha-container w-100">
  <div class="dewha-container1 w-100">
    <div class="d-flex align-items-center justify-content-center">
        <div class="">
            <lazy-image class="dewha-logo w-100" src="https://images.quizell.com/gallery/8611727080242.png"></lazy-image>
        </div>
    </div>
    <!-- Card -->
    <div class="">
        <div class="dewha-card">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="dewha-card-margin d-flex flex-column justify-content-center align-items-center align-items-md-start justify-content-md-start">
                    <div class="get-to-know">GET TO KNOW</div>
                    <h3 class="dewha-started">YOUR SKIN</h3>
                    <div class="dewha-card-description my-3">
                        <div class="">
                            Your journey to <b>perfect skin</b> 
                        </div>
                        <div class="">starts with knowing your skin type. </div>
                    </div>
                    
                    <div class=" d-md-none">
                        <div class="dewha-card-image">
                            <lazy-image  class="w-100" src="https://images.quizell.com/gallery/1651727093699.png"></lazy-image>
                        </div>
                    </div>
                    <div class="dewha-card-description my-3 d-md-none" style="font-size: 12px;">
                        <div class="">
                            Your journey to <b>perfect skin</b> 
                        </div>
                        <div class="">starts with knowing your skin type. </div>
                    </div>
                    <div class="">
                        <button @click="GoToNextPreviewPage()" class="dewha-card-button  mt-3">DISCOVER YOUR SKIN</button>
                    </div>
                </div>
                </div>
                <div class="col-12 col-md-6 d-none d-md-block">
                    <div class="position-relative d-flex align-items-center justify-content-center h-100">
                        <div class=" dewha-card-image d-none d-md-block">
                            <lazy-image  class="w-100" src="https://images.quizell.com/gallery/9021727092033.png"></lazy-image>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="mt-5 pt-5">
        <div class="dewha-heading">WHAT IS THE</div>
        <div class="dewha-big-heading">Skin Type Test?</div>
        <div class="d-flex justify-content-center flex-column align-items-center align-item-center">
            <div class="dewha-quiz-description   my-3">The Skin Type Test is a comprehensive evaluation
that classifies skin based on specific characteristics.
This system helps identify individual skin types, 
enabling a tailored approach to skincare and treatment. 
</div>
<div class="">
                        <button  @click="GoToNextPreviewPage()" class="dewha-card-button d-flex align-items-center justify-content-center">DISCOVER YOUR SKIN <span class="ml-2"><i class="fa-solid fa-chevron-right"></i></span></button>
                    </div>
        </div>
       
       
    </div>
    </div>
    <div class="dewha-footer my-4 d-flex flex-column justify-content-center align-items-center">
        <div class="dewha-footert-text">
            IMPORTANCE OF KNOWING YOUR SKIN TYPE
        </div>
        <div class="dewha-footert-text">
            Understanding your skin type is essential for achieving optimal skin health. 
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
export default {

    computed: {
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
    ])},
methods:{
    ...mapActions([
    "goToNextPreviewPage"]),
    GoToNextPreviewPage() {
      this.goToNextPreviewPage(this.getSelectedPage.value);
    },
}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.dewha-container1{
    padding: 0px 20px 0px 20px;
    --dewha-primary:#F14257;
    --dewha-secondary:#393939;
}
.dewha-container{
    background-image: url(https://images.quizell.com/gallery/2951727157199.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}
.dewha-logo{
    /* width: 234px; */
height: 50px;
margin-top: 17px;
margin-bottom: 10px;
}
.dewha-card{
    border-radius: 70px;
background: rgba(255, 255, 255, 0.61);
box-shadow: 0px 4px 16.1px 6px rgba(0, 0, 0, 0.20);

}
.get-to-know{
    color: var(--dewha-primary);
text-align: center;
font-family: "Work Sans";
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-started{
    color: var(--dewha-primary);
text-align: center;
font-family: "Work Sans";
font-size:40px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.95px;
        display: flex;
        flex-direction: column;
        align-items: center;

}

.dewha-started::after{
    content: '';
    height: 1px;
    width: 60%;
    background-color: var(--dewha-primary);
    display: block;
    margin-top: 0.5rem;
}
.dewha-card-margin{
    margin-top: 20px;
    margin-bottom: 20px;
}
.dewha-card-description {
    color: var(--dewha-primary);
    font-family: "Work Sans";
    font-size: 15px;
    font-style: normal;
    text-align: center;
    line-height: normal;
}
.dewha-card-button{
    border-radius: 81.943px;
background: var(--dewha-primary);
color: #FFF;
text-align: center;
font-family: "Work Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.35px;
border:none;
outline: none;
padding: 16px 21px;
}
.dewha-heading{
    color: var(--dewha-secondary);
text-align: center;
font-family: "Work Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-big-heading{
    color: #393939;
text-align: center;
font-family: "Work Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -2.85px;
}

.dewha-card-image{
        width: 320px;
    }
    .dewha-quiz-description{
    color: var(--dewha-secondary);
text-align: center;
font-family: "Work Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-footert-text{
    color: #393939;
text-align: center;
font-family: "Work Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-card-button span{
    padding: 11px;
    background: #fff;
    border-radius: 50%;
    color: var(--dewha-primary);
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dewha-footer::before{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.60);
    margin-bottom: 1rem;
}
/* Medium devices (tablets) */
@media (min-width: 768px) {
    .dewha-started{
        align-items: flex-start;
    }
    .dewha-card-margin {
    margin-top: 100px;
    margin-bottom: 100px;
    }
    .get-to-know{
        text-align: left;
    }
    .dewha-card-description{
        text-align: left;
        font-size: 24px;

    }
    .dewha-card-image{
        width: 400px;
    }
    .dewha-big-heading{
        font-size: 95px;
    }
    .dewha-heading{
        font-size: 26px;
    }
    .dewha-quiz-description{
    width: 585px;
    font-size: 22px;
}
.dewha-card-button span{
    font-size: 20px;
    width: 30px;
    height: 30px;
}
.dewha-footert-text{
    font-size: 22px;
}
.dewha-logo{
    width: 138px;
height: 60px;
margin-top: 17px;
margin-bottom: 45px;
}
}

/* Large devices (small desktops) */
@media (min-width: 1024px) {
    .dewha-started {
        font-size: 80px; 
        text-align: left;
    }
    .dewha-card{
        padding: 46px 32px;
    }
    .dewha-container1{
        padding: 0px 88px 0px 88px;
    }
    .dewha-card-button{
        font-size: 35px;
    }
    .dewha-card-image{
        width: 480px;
    }
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
    .dewha-started {
        font-size: 90px; /* Size for larger desktops */
        text-align: left;
    }
    .dewha-card{
        padding: 46px 32px;
    }
    .dewha-card-image{
        width: 500px;
    }
}

/* Extra extra large devices (very large screens) */
@media (min-width: 1400px) {
    .dewha-started {
        font-size: 95px; /* Keep it at 95px for very large screens */
        text-align: left;
    }
    
    .dewha-card{
        padding: 46px 32px;
    }
   
    .dewha-card-image{
        width: 570px;
    }
}
</style>